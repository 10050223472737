// 校区管理
<template>
  <div class="campus_management">
    <!-- 视图部分 -->
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="campus_management_content">
          <!-- 顶部的操作框部分 -->
          <div class="top_info margin_top_14">
            <!-- 新增校区 -->
            <div class="com_bt_action search_bt_active" @click.stop="addSchool">新增校区</div>

            <!-- 加盟状态 -->
            <div class="join_state margin_left_20">
              <span class="margin_right_4">加盟状态：</span>
              <el-select @change="changeState" v-model="stateValue" placeholder="请选择">
                <el-option v-for="item in stateArr" :key="item.value" :label="item.value" :value="item.value"> </el-option>
              </el-select>
            </div>

            <!-- 学校 -->
            <div class="school_search margin_left_20">
              <span class="margin_right_4">学校：</span>
              <el-input clearable v-model="schoolValue" placeholder="请输入学校名称或者编码"></el-input>
            </div>

            <!-- 电话 -->
            <div class="phone_search margin_left_20">
              <span class="margin_right_4">联系电话：</span>
              <el-input onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="11" clearable v-model.number="phoneValue" placeholder="请输入联系电话"></el-input>
            </div>
          </div>

          <!-- 底部显示视图部分 -->
          <div class="show_content" v-show="!isShowEmptyView">
            <!-- 表格部分 -->
            <div class="dv_table_view">
              <div class="dv_table_view_content">
                <el-table ref="table_cm" style="width: 100%; margin: auto" border stripe :data="tableData" :max-height="maxHeight">
                  <!-- 序号  -->
                  <el-table-column type="index" label="序号" align="center" width="60">
                    <template scope="scope">
                      <span>{{ (currPage - 1) * pageSize + scope.$index + 1 }}</span>
                    </template>
                  </el-table-column>

                  <!-- 学校编码 -->
                  <el-table-column prop="school_id" align="center" label="学校编码" width="90"></el-table-column>

                  <!-- 学校简称  -->
                  <el-table-column :show-overflow-tooltip="true" prop="school_name" align="center" label="学校简称" width="150"></el-table-column>

                  <!-- 校长手机号  -->
                  <el-table-column prop="principal_tel" align="center" label="校长手机号" width="120"></el-table-column>

                  <!-- 账户密码 -->
                  <el-table-column prop="principal_password" align="center" label="账户密码" width="100"></el-table-column>

                  <!-- 加盟课程 -->
                  <el-table-column prop="sub_name" :show-overflow-tooltip="true" align="center" label="加盟课程" width="180"></el-table-column>

                  <!-- 账户余额 -->
                  <el-table-column align="center" label="账户余额" width="180">
                    <template slot-scope="scope">
                      <!-- 待完善的状态标识没有加盟课程,账户余额一栏显示- -->
                      <div v-if="scope.row.state == -3">-</div>

                      <!-- 不是待完善状态显示账户余额 -->
                      <div v-else>
                        <span>教师：{{ scope.row.teacher_balance }}</span>
                        <!-- 如果没有学生账号就不显示 -->
                        <span class="margin_left_10" v-if="scope.row.isShowStudent">学生：{{ scope.row.student_balance }}</span>
                      </div>
                    </template>
                  </el-table-column>

                  <!-- 加盟状态 -->
                  <el-table-column align="center" label="加盟状态" width="90">
                    <template slot-scope="scope">
                      <div>{{ scope.row.state | getStrByState }}</div>
                    </template>
                  </el-table-column>

                  <!-- 操作 -->
                  <el-table-column label="操作" align="center" min-width="10%">
                    <template slot-scope="scope">
                      <!-- 右边的查看编辑按钮 -->
                      <div class="look_state">
                        <!-- 提醒账户月不足 -->
                        <div class="dv_msg margin_right_20">
                          <span
                            title="账户余额不足"
                            class="span_remain iconfont icon-daibantixing animated infinite swing"
                            @click.stop="doRemindAction(scope.row)"
                            v-if="scope.row.isShowMsg"
                          ></span>
                          <span class="span_remain_msg" v-else>-</span>
                        </div>
                        <!-- 禁用按钮  0:试用  1:正常 -1:失效到期 -2:禁用 -3:待完善 v-if="scope.row.state != -1 && scope.row.state != -3"  -->
                        <el-button :disabled="scope.row.state == -1 || scope.row.state == -3" @click="doUseAction(scope.row)" type="text" size="small">{{
                          scope.row.state != -2 ? "禁用" : "取消禁用"
                        }}</el-button>
                        <el-button @click="doLookAction(scope.row)" type="text" size="small">查看</el-button>
                        <el-button class="margin_left_10" @click="doEditAction(scope.row)" :disabled="scope.row.state == -2" type="text" size="small"
                          >编辑</el-button
                        >
                        <el-button class="margin_left_10" @click="doJoinManager(scope.row)" :disabled="scope.row.state == -2" type="text" size="small"
                          >加盟课程管理</el-button
                        >
                        <!-- 失效到期和禁用的校区不能重置密码 -->
                        <el-button
                          :disabled="scope.row.state == -1 || scope.row.state == -2"
                          class="margin_left_10"
                          @click="resetPwd(scope.row)"
                          type="text"
                          size="small"
                          >重置密码</el-button
                        >
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>

            <!-- 顶部的分页器部分  -->
            <div class="bom_page margin_bom_20" v-if="tableData && tableData.length > 0">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currPage"
                :page-sizes="pageSizeArr"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalSize"
              >
              </el-pagination>
            </div>
          </div>

          <!-- 空视图部分 -->
          <div class="show_content" v-show="isShowEmptyView">
            <EmptyView></EmptyView>
          </div>
        </div>

        <!-- 禁用的弹框 -->
        <DisableSchoolDialog
          ref="del_item"
          v-if="showDelDialog"
          :platformName="platformName"
          :showDialog="showDelDialog"
          @close="showDelDialog = false"
          :title="delTitle"
          @delItem="delItem"
        ></DisableSchoolDialog>

        <!-- 重置密码的弹框 -->
        <ResetPwdDialog
          v-if="isShowResetPwd"
          @close="isShowResetPwd = false"
          :showDialog="isShowResetPwd"
          :resetSchoolId="resetSchoolId"
          type="1"
        ></ResetPwdDialog>
      </template>
    </Layout>
  </div>
</template>
<script>
//禁用校区的弹框
import DisableSchoolDialog from "@/components/project/DisableSchoolDialog";

//重置密码的弹框
import ResetPwdDialog from "@/components/dialog/ResetPwdDialog";

//导入防抖节流函数
import { debounce } from "@/utils/throttle.js";

export default {
  name: "CampusManagement",
  data() {
    return {
      //页面加载loading
      showLoading: -1,

      //是否显示标题栏的二级菜单标题
      isShowSecondTitle: false,

      //二级菜单的标题
      secondTitleName: "",

      //是否显示空视图的标识
      isShowEmptyView: false,

      //加盟状态的值
      stateValue: "所有",

      //输入的学校编码或者关键字
      schoolValue: "",

      //联系电话
      phoneValue: "",

      //加盟状态的数组
      stateArr: [
        {
          value: "所有"
        },
        {
          value: "正常"
        },
        {
          value: "失效"
        },
        {
          value: "试用"
        },
        {
          value: "禁用"
        },
        {
          value: "待完善"
        }
      ],

      //当前页面显示的列表数据
      tableData: [],

      //总的数据条数
      totalSize: "",

      //条数选择的数据
      pageSizeArr: [10, 12, 14],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //是否显示禁用弹框的控制值
      showDelDialog: false,

      //禁用标题
      delTitle: "禁用校区",

      //禁用或者取消禁用的课程名称
      platformName: "",

      //禁用或者取消禁用操作的条目
      platformObj: null,

      //操作校区的id
      school_id: -1,

      //禁用状态传值
      disable: -1,

      //表格的最大高度
      maxHeight: 0,

      //是否是第一次进入
      isFirstEnter: true,

      //表格部分的最小宽度
      tableWidth: 0,

      //是否显示重置密码的弹框
      isShowResetPwd: false,

      //需要重置密码的校区ID
      resetSchoolId: ""
    };
  },
  created() {},

  mounted() {
    //初始化数据
    this.initData();

    //进入页面时同步一下页面参数
    this.sysPageParam();

    //请求页面数据
    this.httpData();
  },

  methods: {
    //初始化表格的最大高度
    initData() {
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 76 - 44 - 90;
      this.tableWidth = this.getTabWidth();
    },

    /**
     * @param type = 2 固定参数
     * @param state 加盟状态 默认传入所有  空:所有 0:试用  1:正常 -1:失效到期 -2:禁用 -3:待完善
     * @param school_name 学校名称 有就传,没有就不传
     * @param contacts_tel 联系电话 有就传,没有就不传
     * @param page 页码 主要服务器是从0开始的
     * @param pagecount 每页显示的条数
     */
    httpData() {
      //同步一下页面参数
      this.savePageParam();
      let param = this.getHttpParams();
      param.type = 2;
      param.page = this.currPage - 1;
      param.pagecount = this.pageSize;
      //加盟状态
      if (!this.isEmpty(this.getState())) {
        param.state = this.getState();
      } else {
        param.state = "";
      }
      //学校
      if (!this.isEmpty(this.schoolValue)) {
        param.school_name = this.schoolValue;
      } else {
        param.school_name = "";
      }
      //联系电话
      if (!this.isEmpty(this.phoneValue)) {
        param.principal_tel = this.phoneValue;
      } else {
        param.principal_tel = "";
      }

      this.$http
        .fetchPost(this.$api.SCHOOL_MANAGER, param)
        .then(res => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;

          // console.log("请求到的数据是:", resulte);

          //将表格滚动到顶部
          this.$nextTick(() => {
            this.$refs.table_cm.bodyWrapper.scrollTop = 0;
          });

          if (resulte.state == 200) {
            //获取总条数
            this.totalSize = Number.parseInt(resulte.data.school_total);
            if (this.totalSize != 0) {
              //证明有数据
              this.isShowEmptyView = false;

              //获取列表数据
              this.tableData = resulte.data.school_list;

              //组装一下表格数据,显示省市区的地址信息
              this.tableData.forEach(item => {
                //拼接需要显示的地址信息 province省 city市 country区
                let showAddress = "";
                if (!this.isEmpty(item.province) && !this.isEmpty(item.city)) {
                  if (item.province != item.city) {
                    //不是直辖市,省和市不同并且市和区不同
                    if (!this.isEmpty(item.county) && item.city !== item.county) {
                      showAddress = item.province + item.city + item.county;
                    } else {
                      showAddress = item.province + item.city;
                    }
                  } else {
                    //直辖市
                    if (!this.isEmpty(item.county)) {
                      showAddress = item.province + item.county;
                    } else {
                      showAddress = item.province;
                    }
                  }
                }

                //判断地址是否为空,不为空的情况在进行展示
                if (!this.isEmpty(item.address) && item.address != "undefined") {
                  showAddress += item.address;
                }

                if (!this.isEmpty(showAddress)) {
                  this.$set(item, "showAddress", showAddress);
                } else {
                  this.$set(item, "showAddress", "-");
                }

                //加盟课程
                if (this.isEmpty(item.sub_name)) {
                  //没有加盟课程
                  item.sub_name = "-";
                } else {
                  //有加盟课程
                  //判断加盟课程是否包含学生账号
                  if (
                    item.sub_name.includes("程序蛙少儿编程") ||
                    item.sub_name.includes("NCT") ||
                    item.sub_name.includes("GIE") ||
                    item.sub_name.includes("悟空寻宝")
                  ) {
                    //需要显示学生账户数
                    //是否显示学生数量
                    item.isShowStudent = true;
                    //学生数量的值
                    item.student_balance = item.student_balance;

                    //只有学校不是待完善,禁用，以及失效到期状态,并且教师数量和学生数量同时为0时才显示消息提醒标识,否则显示-
                    if (item.state != -3 && item.state != -2 && item.state != -1 && item.student_balance == 0 && item.teacher_balance == 0) {
                      //如果不是待完善并且不是禁用状态并且没有到期,显示提醒按钮
                      item.isShowMsg = true;
                    } else {
                      //显示-
                      item.isShowMsg = false;
                    }
                  } else {
                    //不显示学生账户数
                    //学生数量的值
                    item.student_balance = "-";
                    //是否显示学生数量
                    item.isShowStudent = false;
                    //只有学校不是待完善,禁用，以及失效到期状态,并且教师数量为0时才显示消息提醒标识,否则显示-
                    if (item.state != -3 && item.state != -2 && item.state != -1 && item.student_balance == 0) {
                      item.isShowMsg = true;
                    } else {
                      item.isShowMsg = false;
                    }
                  }
                }

                //判断手机号码是否存在
                if (this.isEmpty(item.principal_tel)) {
                  item.principal_tel = "-";
                }
              });
            } else {
              //显示空视图
              this.isShowEmptyView = true;
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch(err => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //获取加盟状态
    getState() {
      let res = "";
      if (this.isEmpty(this.stateValue) || this.stateValue == "所有") {
        res = "";
      } else if (this.stateValue == "试用") {
        res = "0";
      } else if (this.stateValue == "正常") {
        res = "1";
      } else if (this.stateValue == "失效") {
        res = "-1";
      } else if (this.stateValue == "禁用") {
        res = "-2";
      } else if (this.stateValue == "待完善") {
        res = "-3";
      }
      return res;
    },

    //增加校区
    addSchool() {
      //新增校区的时候还原一下页面参数，保证返回的时候是第一页的数据
      this.savePageParam();
      this.$router.push({
        path: "/Home/CampusEdit",
        query: { type: 1, mode: "CampusManagement" }
      });
    },

    //搜索
    search() {
      if (this.isArrowSearch) {
        //如果有电话号码判断电话号码的正确性
        if (!this.isEmpty(this.phoneValue)) {
          if (!this.testPhone(this.phoneValue)) {
            !this.testPhone(this.phoneValue);
            this.showWarnMsg("手机格式不正确");
            return;
          }
        }

        //还原页码到第一页
        this.currPage = 1;

        //刷新数据
        this.httpData();
      }
    },

    //选中值发生变化时触发
    changeState(val) {
      this.stateValue = val;

      //还原页码到第一页
      this.currPage = 1;

      //刷新数据
      this.httpData();
    },

    //编辑的操作
    doEditAction(item) {
      //跳转时保存一下页面参数
      this.savePageParam();

      //跳转到CampusEdit界面 并携带当前条目的参数进行过去 type表示是编辑跳转 state表示条目的加盟状态
      let schoolInfo = JSON.stringify(item);

      this.$router.push({
        path: "/Home/CampusEdit",
        query: {
          mode: "CampusManagement",
          type: 2,
          schoolInfo: schoolInfo,
          state: item.state
        }
      });
    },

    //查看
    doLookAction(item) {
      //跳转时保存一下页面参数
      this.savePageParam();

      //保存一下学校信息
      localStorage.setItem(this.$key.LOOK_SCHOOL_INFO, JSON.stringify(item));

      //跳转到校区详情界面
      this.$router.push({
        path: "/Home/LookCampusInfo"
      });
    },

    //禁用的操作
    doUseAction(item) {
      //显示禁用的弹框
      this.showDelDialog = true;
      this.school_id = item.school_id;
      this.platformName = item.sub_name;
      this.platformObj = item;

      if (item.state != -2) {
        this.delTitle = "禁用校区";
        this.disable = 1;
      } else {
        this.delTitle = "取消禁用";
        this.disable = 0;
      }
    },

    //提醒的操作
    async doRemindAction(item) {
      let param = this.getHttpParams();
      param.type = 6;
      param.sid = item.school_id;
      // param.school_id = item.school_id;

      // let res = await this.$http.fetchPost(this.$api.SCHOOL_MANAGER, param);
      // if (res.data.state == 200) {
      //   this.showSuccessMsg("提醒续费成功");
      // } else {
      //   this.showErrorMsg(this.getMesage(res.data.state));
      // }

      let res = await this.$http.fetchPost(this.$api.MESSAGEREMINDER, param);
      if (res.data.state == 200) {
        this.showSuccessMsg("提醒续费成功");
      } else {
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },

    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log("当前页显示的条数是:", this.pageSize);
      //重新刷新数据
      this.httpData();
    },

    //页面改变时触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currPage = val;
      //重新刷新数据
      this.httpData();
    },

    //禁用校区/取消禁用校区
    async delItem() {
      //disable传1  禁用；传0  取消禁用
      let param = this.getHttpParams();
      param.type = 5;
      param.school_id = this.school_id;
      param.disable = this.disable;

      let res = await this.$http.fetchPost(this.$api.SCHOOL_MANAGER, param);

      if (res.data.state == 200 || res.data.state == 504) {
        //关闭弹窗Loading
        this.$refs.del_item.showCommitLoading = false;

        //关闭弹框
        this.showDelDialog = false;
        if (res.data.state == 200) {
          this.showSuccessMsg("操作成功");
          //重新刷新数据
          this.httpData();
        } else {
          //直接将服务器返回的错误内容弹出提示
          this.showErrorMsg(this.getMesage(res.data.state));
        }
      } else {
        //关闭弹窗Loading
        this.$refs.del_item.showCommitLoading = false;
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },

    //加盟课程管理
    doJoinManager(item) {
      //使用用localStorage保存一下当前的学校信息
      localStorage.setItem(this.$key.SCHOOL_INFO_STR, JSON.stringify(item));

      //跳转时保存一下页面参数
      this.savePageParam();

      //跳转到加盟课程管理界面
      this.$router.push({
        path: "/Home/ManagerJoinLessons"
      });
    },

    //重置密码
    resetPwd(item) {
      //记录重置密码的校区id,并显示弹框
      this.resetSchoolId = item.school_id;
      this.isShowResetPwd = true;
    },

    //清空参数
    clearPageParam() {
      //当前页码
      localStorage.removeItem(this.$key.SCHOOL_MANAGER_CURRPAGE);

      //每页的条数
      localStorage.removeItem(this.$key.SCHOOL_MANAGER_CURR_PAGE_SIZE);

      //加盟状态
      localStorage.removeItem(this.$key.SCHOOL_MANAGER_STATE_VALUE);

      //学校名称或者编码
      localStorage.removeItem(this.$key.SCHOOL_MANAGER_SCHOOL_VALUE);

      //联系电话
      localStorage.removeItem(this.$key.SCHOOL_MANAGER_PHONE_VALUE);
    },

    //跳转时保存当前页码,条数
    savePageParam() {
      //当前页面
      localStorage.setItem(this.$key.SCHOOL_MANAGER_CURRPAGE, this.currPage);

      //每页的条数
      localStorage.setItem(this.$key.SCHOOL_MANAGER_CURR_PAGE_SIZE, this.pageSize);

      //加盟状态
      localStorage.setItem(this.$key.SCHOOL_MANAGER_STATE_VALUE, this.stateValue);

      //学校名称或者编码
      localStorage.setItem(this.$key.SCHOOL_MANAGER_SCHOOL_VALUE, this.schoolValue);

      //联系电话
      localStorage.setItem(this.$key.SCHOOL_MANAGER_PHONE_VALUE, this.phoneValue);
    },

    //进入页面时同步页面参数
    sysPageParam() {
      //当前页面
      let currPage = localStorage.getItem(this.$key.SCHOOL_MANAGER_CURRPAGE);
      if (!this.isEmpty(currPage)) {
        this.currPage = parseInt(currPage);
      }

      //每页的条数
      let pageSize = localStorage.getItem(this.$key.SCHOOL_MANAGER_CURR_PAGE_SIZE);
      if (!this.isEmpty(pageSize)) {
        this.pageSize = parseInt(pageSize);
      }

      //加盟状态
      let stateValue = localStorage.getItem(this.$key.SCHOOL_MANAGER_STATE_VALUE);
      if (!this.isEmpty(stateValue)) {
        this.stateValue = stateValue;
      }

      //学校名称或者编码
      let schoolValue = localStorage.getItem(this.$key.SCHOOL_MANAGER_SCHOOL_VALUE);
      if (!this.isEmpty(schoolValue)) {
        this.schoolValue = schoolValue;
      }

      //联系电话
      let phoneValue = localStorage.getItem(this.$key.SCHOOL_MANAGER_PHONE_VALUE);
      if (!this.isEmpty(phoneValue)) {
        this.phoneValue = phoneValue;
      }
    }
  },

  filters: {
    //空:所有 0:试用  1:正常 -1:失效到期 -2:禁用 -3:待完善
    getStrByState(state) {
      let res = "";
      switch (Number.parseInt(state)) {
        case 0:
          res = "试用";
          break;
        case 1:
          res = "正常";
          break;
        case -1:
          res = "失效";
          break;
        case -2:
          res = "禁用";
          break;
        case -3:
          res = "待完善";
          break;
        default:
          break;
      }

      return res;
    }
  },

  watch: {
    //使用防抖函数
    schoolValue: debounce(function() {
      //还原页码到第一页
      this.currPage = 1;
      //刷新页面数据
      this.httpData();
    }, 500),

    //使用防抖函数
    phoneValue: debounce(function() {
      //还原页码到第一页
      this.currPage = 1;
      //刷新页面数据
      this.httpData();
    }, 500)
  },

  computed: {
    //搜索框是否可以操作的控制值, 这里使用计算属性来操作
    isArrowSearch: function() {
      if (this.isEmpty(this.stateValue) && this.isEmpty(this.schoolValue) && this.isEmpty(this.phoneValue)) {
        return false;
      } else {
        return true;
      }
    }
  },

  components: { DisableSchoolDialog, ResetPwdDialog }
};
</script>
<style lang="scss" scoped>
.campus_management {
  width: 100%;
  height: calc(100vh - 77px);
  min-width: $tab_min_width;
  .campus_management_content {
    height: 100%;
    display: flex;
    margin: 0 10px;
    flex-direction: column;
    ::v-deep .el-table .cell {
      text-align: center;
    }

    //顶部的操作栏部分
    .top_info {
      // height: 54px;
      display: flex;
      align-items: center;
      min-width: $tab_min_width;
      //学校
      .school_search {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
        padding-left: 6px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input {
        width: 210px;
      }

      ::v-deep .el-input__suffix {
        right: 0px;
      }
    }

    //内容部分
    .show_content {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;

      //表格视图
      .dv_table_view {
        margin-top: 4px;
        flex: 1;
        .dv_table_view_content {
          flex: 1;
          .span_remain {
            font-size: 18px;
            cursor: pointer;
            color: red;
          }

          .span_remain_msg {
            font-size: 16px;
          }

          .look_state {
            display: flex;
            align-content: center;
            justify-content: center;

            .dv_msg {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
            }
          }

          .com_tab_item {
            display: flex;
            flex-direction: column;
            height: 42px;
            align-items: center;
            justify-content: center;
          }

          .action_item {
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: start;
          }
        }
      }

      //底部的分页器部分
      .bom_page {
        display: flex;
        align-items: center;
        height: 40px;
        justify-content: center;
      }
    }
  }
}
</style>
